import React from "react";
import Nosotros from "Components/Nosotros";
import { StaticImage } from "gatsby-plugin-image";

const NosotrosPage = ({ location }) => {
  const header = <StaticImage src="../../images/nosotros-cover.jpg" transformOptions={{ quality: 100 }} loading="eager" placeholder="blurred" className="_cover-image" />;
  return (
    <Nosotros location={location} header={header} />
  );
};

export default NosotrosPage;
