import React from 'react';
import PageWithSubMenu from 'Commons/PageWithSubmenu';
import './styles.scss';
import { StaticImage } from 'gatsby-plugin-image';
import DownloadLinkIcon from '../../images/download-link-icon.svg';
import iso0991PDF from '../../downloads/certificado-9001_2024.pdf';
import certificadoFabricacion from '../../downloads/certificado-fabricacion.pdf';
import certificadoInstalacion from '../../downloads/certificado-instalacion.pdf';
import certificadoTallerDeModificacion from '../../downloads/certificado-taller-de-modificacion.pdf';
import certificadoMB from '../../downloads/certificado-mb.pdf';
import politicaDeCalidad from '../../downloads/politica-de-calidad.pdf';
import verificadorRepotenciador from '../../downloads/verificador-repotenciador.pdf';
import distribuidorDesguace from '../../downloads/distribuidor-desguace.pdf';

const DownloadItem = ({ link, text }) => (
  <li className="download-item">
    <DownloadLinkIcon />
    <div className="download-item--data">
      <p className="download-item--text">{text}</p>
      <a className="_link" href={link} download>
        Descargar certificado
      </a>
    </div>
  </li>
);

const Nosotros = ({ location, header }) => {
  const currentPath = location.pathname;
  return (
    <PageWithSubMenu
      submenuItems={[
        {
          title: 'NOSOTROS',
          to: '#nosotros',
        },
        {
          title: 'HISTORIA',
          to: '#historia',
        },
        {
          title: 'CERTIFICADOS',
          to: '#certificados',
        },
      ]}
      currentPath={currentPath}
      header={header}
    >
      <h2 className="_sub-heading content--title" id="nosotros">
        Empresa líder en vehículos blindados.
      </h2>
      <p className="_content-paragraph">
        Somos una empresa líder en Argentina con más de 55 años, formada por un
        grupo de profesionales especializados en el diseño y producción de
        vehículos blindados y elementos de seguridad para las fuerzas armadas,
        empresas privadas y civiles. <br />
        Cada producto que se desarrolla en nuestra planta sigue las estrictas
        normas del Sistema de Gestión y Calidad ISO 9001. <br />
        Todos los materiales están previamente testeados y certificados por
        ANMaC. Creemos que si no podemos imponer y cumplir con nuestras
        estrictas pautas en la fabricación de vehículos blindados, no deberíamos
        estar en el negocio en primer lugar.
      </p>
      <StaticImage
        src="../../images/nosotros-taller.jpg"
        className="content--image"
      />
      <p className="_content-paragraph">
        Nuestro objetivo es no solo entregar un producto de la más alta calidad
        sino también ofrecer un servicio al cliente estelar, una respuesta
        rápida y recomendaciones bien informadas cuando se trata de seleccionar
        el tipo de blindado que se adecúe a la necesidades y posibilidades de
        cada público. <br />
        <br />
        Tenemos una planta industrial de más de 8.000 m2 sobre ruta 2, km 37,5,
        Berazategui, en donde más de 50 personas trabajan para ofrecer el mejor
        servicio. <br />
        Nuestro departamento de ingeniería investiga y desarrolla nuevos
        materiales para ser innovadores sin dejar de lado la calidad y seguridad
        del producto. <br />
        Nos enorgullece ser la única empresa del mercado en tener su propia
        fábrica de vidrios blindados de alta tecnología. Los vidrios pueden ser
        fabricados tanto curvos, para reemplazo de productos originales en la
        industria automotriz y planos para construcciones que requieran este
        tipo de aplicación. Buscamos fabricar cristales blindados de alta
        tecnología y menor peso para que ni usted note el cambio en su vehículo.{' '}
        <br />
        <br />
        Somos líderes en el mercado, llegamos a este título gracias a nuestros
        años de experiencia, infraestructura y desarrollo e innovación
        tecnológica.
      </p>
      <h2 className="_sub-heading content--title" id="historia">
        Tenemos 55 años de historia.
      </h2>
      <p className="_content-paragraph">
        Por más de 55 años, la industria de blindaje ha confiado en Interservit
        para suministrar soluciones de blindaje avanzadas. Ha estado
        proporcionando transportadores de caudales, búnkers de seguridad,
        tesoros, puertas y otros elementos blindados para bancos y entidades
        financieras. Además, suministró camiones tácticos y antidisturbios,
        torres vigías móviles blindadas, vallas de seguridad y puestos móviles
        blindados para las fuerzas de seguridad.
        <br />
        En los últimos 20 años Interservit trasladó su experiencia y éxito en la
        producción de autos blindados de calidad para civiles, a través de un
        enfoque individual de las necesidades y requisitos de cada uno de sus
        clientes.
      </p>
      <p className="_accent-text content--quote">
        No importa cuáles son sus necesidades de protección, usted rápidamente
        descubrirá desde su primera interacción con nuestros profesionales
        altamente entrenados, que Interservit es su mejor opción en la industria
        del blindaje.
      </p>
      <h2 className="_sub-heading content--title" id="certificados">
        Optá por un fabricante con las certificaciones adecuadas.
      </h2>
      <p className="_content-paragraph">
        Contamos con todas las autorizaciones de blindados de la anmac: registro
        nacional de armas, ente que certifica todos los vehículos que están
        blindados en la vía pública.
        <br />
        Contamos con todas las inscripciones del ministerio de industria y
        transporte: esto nos habilita a generar el permiso para que usted pueda
        transitar en la vía pública con el vehículo blindado bajo las normas de
        transporte y tránsito.
        <br />
        Sabemos que blindar un auto es una inversión, por eso tiene sentido
        optar por un fabricante con las certificaciones adecuadas para
        garantizar la seguridad de todos dentro del vehículo.
        <br />
      </p>
      <StaticImage
        src="../../images/nosotros-certificados.jpg"
        className="content--image"
      />
      <br />
      <a href={politicaDeCalidad} download className="_link">
        POLÍTICA DE CALIDAD
      </a>
      <br />
      <a href={iso0991PDF} download className="_link">
        CERTIFICADO ISO 9001
      </a>
      <ul className="downloads">
        <DownloadItem
          text="GARANTÍA MERCEDES BENZ SOBRE NUESTRAS UNIDADES BLINDADAS"
          link={certificadoMB}
        />
        <DownloadItem
          text="CERTIFICADO DE FABRICANTE DE MATERIALES DE USOS ESPECIALES"
          link={certificadoFabricacion}
        />
        <DownloadItem
          text="CERTIFICADO DE HABILITACIÓN DE INSTALACIÓN"
          link={certificadoInstalacion}
        />
        <DownloadItem
          text="CERTIFICADO DE DISTRIBUIDOR MAYORISTA - DESGUACE"
          link={distribuidorDesguace}
        />
        <DownloadItem
          text="CERTIFICADO DE HABILITACIÓN TALLER DE MODIFICACIÓN"
          link={certificadoTallerDeModificacion}
        />
        <DownloadItem
          text="CERTIFICADO DE VERIFICADOR Y REPOTENCIADOR"
          link={verificadorRepotenciador}
        />
      </ul>
    </PageWithSubMenu>
  );
};

export default Nosotros;
