import React, { useState } from 'react';
import PageWrapper from 'Commons/PageWrapper';
import { Container, Row, Col } from 'react-grid-system';
import './styles.scss';
import { Link } from 'gatsby';
import SubMenuArrow from '../../../images/submenu-arrow.svg';

const SideMenuItem = ({ children, selected }) => (
  <li className={selected ? 'selected' : ''}>
    {children}
    <SubMenuArrow />
  </li>
);

// submenuItems: [{
//   title: String,
//   to: String
// }...]
const PageWithSubMenu = ({ submenuItems = [], children, header = null }) => {
  const [currentPath, setCurrentPath] = useState(submenuItems[0].title);
  return (
    <PageWrapper>
      {header}
      <article className="page-with-submenu _center">
        <Container>
          <Row>
            <Col sm={12} md={2} lg={2}>
              <ul className="page-with-submenu--submenu">
                {submenuItems?.map(({ title, to }) => (
                  <Link to={to} onClick={() => setCurrentPath(title)}>
                    <SideMenuItem selected={currentPath === title}>
                      {title}
                    </SideMenuItem>
                  </Link>
                ))}
              </ul>
            </Col>
            <Col sm={12} md={10} lg={10} xl={6}>
              {children}
            </Col>
          </Row>
        </Container>
      </article>
    </PageWrapper>
  );
};

export default PageWithSubMenu;
